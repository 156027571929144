<style>
.counter {
    height: min-content !important;
    font-size: 13px;
    color: white;
    width: min-content !important;
}
</style>

<template>
    <Card title="Validação dos Dados">
        <p class="description mt-4 text-lg">Precisamos validar alguns dados informados, mas não se preocupe se não
            conseguir resolver todas as inconsistências nesse momento, pode continuar utilizando o seu número de
            protocolo.
        </p>
        <input class="hidden" type="hidden" id="copy" :value="protocol">
        <p class="text-center protocol" @click="copy(protocol)">{{ message ? message : protocol }}</p>
        <div class="grid grid-cols-12 gap-5 my-12" v-if="!loadingData">
            <div class="p-3 rounded-lg shadow-md col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 gap-4">
                <div class="w-full flex justify-between gap-3">
                    <h1 class="text-lg mb-3">{{clientType === 'software-houses' ? 'Dados da Software House' : 'Dados do Estabelecimento'}}</h1>
                    
                    <div v-if="errorCounter(data.empresa) + errorCounter(preRegister) > 0"
                        class="rounded-full bg-red-500 px-2 max-w-min h-min counter">{{ errorCounter(data.empresa) + errorCounter(preRegister) }}
                    </div>
                </div>
                <h2 class="text-base mb-2 truncate">Nome: {{ data.empresa.NomeFantasia }}</h2>
                <Validator type="email" v-if="preRegisterData.email" :formatedValue="preRegisterData.email" :value="preRegisterData.email"
                    :validated="preRegisterData.EmailValidated" :validating="!validatingEmails.empresa"
                    @getCode="getCode(preRegisterData.email, 'email', 'empresa')" @onChange="onChange" />
                <Validator type="email" :formatedValue="data.empresa.Email" :value="data.empresa.Email"
                    :validated="data.empresa.EmailValidated" :validating="!validatingEmails.empresa"
                    @getCode="getCode(data.empresa.Email, 'email', 'empresa')" @onChange="onChange" />
                <div class="items-center flex justify-between gap-5">
                    <div class="col-span-12 lg:col-span-6 mb-2">CNPJ: {{ applyMask('CNPJ', data.empresa.CNPJ) }}</div>
                </div>
                <div class="items-center flex justify-between gap-5">
                    <div class="truncate mb-2">CNAE: {{ applyMask('CNAE', data.empresa.CNAE) }}</div>
                </div>
            </div>
            <div class="p-3 rounded-lg shadow-md col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 gap-4"
                v-if="data.representantes.length > 0">
                <div class="w-full flex justify-between gap-3">
                    <h1 class="text-lg mb-3">Dados dos Representantes</h1>
                    <div v-if="errorCounter(data.representantes[0]) > 0"
                        class="rounded-full bg-red-500 px-2 max-w-min h-min counter">
                        {{ errorCounter(data.representantes[0]) }}</div>
                </div>
                <div v-for="representante in data.representantes" v-bind:key="representante">
                    <h2 class="text-base truncate">Nome: {{ representante.NomeCompleto }}</h2>
                    <Validator class="mb-2" type="email" :formatedValue="representante.Email"
                        :value="representante.Email" :validated="representante.EmailValidated"
                        :validating="validatingEmails.representante"
                        @getCode="getCode(representante.Email, 'email', 'representante')" @onChange="onChange" />
                    <Validator class="mb-2" type="telefone" :formatedValue="applyMask('Celular', representante.Celular)"
                        :value="representante.Celular" :validated="representante.CelularValidated"
                        :validating="!validatingPhones.representante"
                        @getCode="getCode(representante.Celular, 'cellphone', 'representante')" @onChange="onChange" />
                    <div class="items-center flex justify-between gap-5">
                        <div class="truncate">CPF: {{ applyMask('CPF', representante.CPF) }}</div>
                    </div>
                </div>
            </div>
            <div v-if="data.tecnico && clientType === 'software-houses'"
                class="p-3 rounded-lg shadow-md col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 gap-4">
                <div class="w-full flex justify-between gap-3">
                    <h1>Dados do Responsável Técnico</h1>
                    <div v-if="errorCounter(data.tecnico.responsavel) > 0"
                        class="rounded-full bg-red-500 px-2 max-w-min h-min counter">
                        {{ errorCounter(data.tecnico.responsavel) }}</div>
                </div>
                <div>
                    <h2 class="text-base mb-2 truncate"> Nome: {{ data.tecnico.responsavel.NomeCompleto }}</h2>
                    <Validator type="email" :formatedValue="data.tecnico.responsavel.Email"
                        :value="data.tecnico.responsavel.Email" :validated="data.tecnico.responsavel.EmailValidated"
                        :validating="validatingEmails.responsavel"
                        @getCode="getCode(data.tecnico.responsavel.Email, 'email', 'tecnico')" @onChange="onChange" />
                    <Validator type="telefone" :formatedValue="applyMask('Celular', data.tecnico.responsavel.Celular)"
                        :value="data.tecnico.responsavel.Celular" :validated="data.tecnico.responsavel.CelularValidated"
                        :validating="validatingPhones.responsavel"
                        @getCode="getCode(data.tecnico.responsavel.Celular, 'cellphone', 'tecnico')"
                        @onChange="onChange" />
                    <div v-if="data.tecnico && clientType === 'software-houses'" class="items-center flex justify-between gap-5">
                        <div class="truncate">CPF: {{ applyMask('CPF', data.tecnico.responsavel.CPF) }}</div>
                    </div>
                </div>
            </div>
            <div v-if="data.responsavel && clientType === 'company'"
                class="p-3 rounded-lg shadow-md col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 gap-4">
                <div class="w-full flex justify-between gap-3">
                    <h1 class="text-lg mb-3">Dados do Responsável Financeiro</h1>
                    <div v-if="errorCounter(data.responsavel) > 0"
                        class="rounded-full bg-red-500 px-2 max-w-min h-min counter">{{ errorCounter(data.responsavel)
                        }}
                    </div>
                </div>
                <div>
                    <h2 class="text-base truncate">Nome: {{ data.responsavel.NomeCompleto }}</h2>
                    <Validator class="mb-2" type="email" :formatedValue="data.responsavel.Email"
                        :value="data.responsavel.Email" :validated="data.responsavel.EmailValidated"
                        :validating="validatingEmails.financeiro"
                        @getCode="getCode(data.responsavel.Email, 'email', 'financeiro')" @onChange="onChange" />
                    <Validator class="mb-2" type="cellphone"
                        :formatedValue="applyMask('Celular', data.responsavel.Celular)"
                        :value="data.responsavel.Celular" :validated="data.responsavel.CelularValidated"
                        :validating="validatingPhones.financeiro"
                        @getCode="getCode(data.responsavel.Celular, 'cellphone', 'financeiro')" @onChange="onChange" />
                    <div class="items-center flex justify-between gap-5">
                        <div class="truncate">CPF: {{ applyMask('CPF', data.responsavel.CPF) }}</div>
                    </div>
                </div>
            </div>
            <div v-if="data.tecnico && clientType === 'software-houses'"
                class="p-3 rounded-lg shadow-md col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 gap-4">
                <div class="w-full flex justify-between gap-3">
                    <h1>Dados do Técnico</h1>
                    <div v-if="errorCounter(data.tecnico.tecnico) > 0"
                        class="rounded-full bg-red-500 px-2 max-w-min counter">{{ errorCounter(data.tecnico.tecnico) }}
                    </div>
                </div>
                <div>
                    <h2 class="truncate">Nome: {{ data.tecnico.tecnico.NomeCompleto }}</h2>
                    <Validator type="email" :formatedValue="data.tecnico.tecnico.Email"
                        :value="data.tecnico.tecnico.Email" :validated="data.tecnico.tecnico.EmailValidated"
                        :validating="validatingEmails.tecnico"
                        @getCode="getCode(data.tecnico.tecnico.Email, 'email', 'tecnico')" @onChange="onChange" />
                    <Validator type="telefone" :formatedValue="applyMask('Celular', data.tecnico.tecnico.Celular)"
                        :value="data.tecnico.tecnico.Celular" :validated="data.tecnico.tecnico.CelularValidated"
                        :validating="validatingPhones.tecnico"
                        @getCode="getCode(data.tecnico.tecnico.Celular, 'cellphone', 'tecnico')" @onChange="onChange" />
                    <div class="items-center flex justify-between gap-5">
                        <div class="truncate">CPF: {{ applyMask('CPF', data.tecnico.tecnico.CPF) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="w-full h-96 flex">
            <FontAwesomeIcon class="m-auto self-center animate-spin text-primary" :icon="spinner" />
        </div>
        <div class="flex">
            <Button v-if="(clientType === 'company' && this.$store.state.materaAccountStatus !== 'regular') || clientType === 'software-houses'" @click="back" :icon="backIcon" iconPosition="left" label="Voltar"
                class="ml-auto hover:bg-gray-300" />
            <PrimaryButton :disabled="!nextable && !isTesting()" @click="next" :icon="nextIcon" iconPosition="right"
                :loading="loading" label="Prosseguir" :class="(clientType === 'company' && this.$store.state.materaAccountStatus !== 'regular') || clientType === 'software-houses' ? 'ml-5 next' : 'ml-auto next'" />
        </div>
    </Card>
</template>

<script>
import Card from '../../../ui/Card.vue'
import PrimaryButton from '../../../buttons/PrimaryButton'
import Button from '../../../buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheckCircle, faChevronLeft, faChevronRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import Validator from '../../../business/Validator'
import api from '@/services/api';
import utils from '@/utils'

export default {
    name: 'ValidateData',
    props: ['form', 'preRegister', 'protocol', 'clientType', 'clientId'],
    components: {
        FontAwesomeIcon,
        Card,
        PrimaryButton,
        Button,
        Validator
    },
    data() {
        return {
            message: null,
            loadingData: true,
            loading: false,
            data: {},
            preRegisterData: {},
            successIcon: faCheckCircle,
            spinner: faCircleNotch,
            nextIcon: faChevronRight,
            backIcon: faChevronLeft,
            validateStatus: null,
            nextable: false,
            validatingEmails: {},
            validatingPhones: {},
        }
    },
    async created() {
        this.data = await this.form
        this.preRegisterData = await this.preRegister
        await this.initializeValidate()
        this.loadingData = false
    },
    methods: {
        isTesting() {
            return process.env.VUE_APP_NODE_ENV == 'test'
        },

        next() {
            this.loading = true
            this.$emit('next')
        },

        back() {
            this.$emit('back')
        },

        async initializeValidate() {
            if (this.data != {}) {
                this.data.empresa.EmailValidated = await this.isAbleToValidate(this.data.empresa.Email, 'email')
                if (this.clientType === 'software-houses') {
                    this.data.tecnico.responsavel.CelularValidated = await this.isAbleToValidate(this.data.tecnico.responsavel.Celular, 'cellphone')
                    this.data.tecnico.responsavel.EmailValidated = await this.isAbleToValidate(this.data.tecnico.responsavel.Email, 'email')
                    this.data.tecnico.tecnico.CelularValidated = await this.isAbleToValidate(this.data.tecnico.tecnico.Celular, 'cellphone')
                    this.data.tecnico.tecnico.EmailValidated = await this.isAbleToValidate(this.data.tecnico.tecnico.Email, 'email')
                    this.preRegisterData.EmailValidated = await this.isAbleToValidate(this.preRegisterData.email, 'email')
                } else {
                    this.data.responsavel.CelularValidated = await this.isAbleToValidate(this.data.responsavel.Celular, 'cellphone')
                    this.data.responsavel.EmailValidated = await this.isAbleToValidate(this.data.responsavel.Email, 'email')
                }
                
                for (let i = 0; i < this.data.representantes.length; i++) {
                    if (this.data.representantes[i]) {
                        this.data.representantes[i].CelularValidated = await this.isAbleToValidate(this.data.representantes[i].Celular, 'cellphone')
                        this.data.representantes[i].EmailValidated = await this.isAbleToValidate(this.data.representantes[i].Email, 'email')
                    }
                }
                const hasARepresentativeWithPendingValidation = this.data.representantes.find((representative) => {
                    return representative.CelularValidated || representative.EmailValidated
                })

                if (
                    (
                        !this.data.responsavel && (
                            !this.data.tecnico.tecnico.EmailValidated &&
                            !this.data.tecnico.tecnico.CelularValidated &&
                            !this.data.tecnico.responsavel.EmailValidated &&
                            !this.data.tecnico.responsavel.CelularValidated &&
                            !this.data.empresa.EmailValidated &&
                            !hasARepresentativeWithPendingValidation
                        )
                    ) || 
                    (
                        this.data.responsavel && (
                            !this.data.responsavel.EmailValidated &&
                            !this.data.responsavel.CelularValidated &&
                            !this.data.empresa.EmailValidated &&
                            !hasARepresentativeWithPendingValidation
                        )
                    )
                ) {
                    this.nextable = true
                }
            }
        },

        async isAbleToValidate(value, type) {
            const token = localStorage.getItem('token')
            let isAble = false;

            this.data.validations?.forEach((validation) => {
                if (type == 'telefone' || type === 'cellphone') {
                    if (validation.type === type && this.removeMask(validation.value) === this.removeMask(value)) {
                        isAble = validation.isValidated
                    }
                }
                if (type == 'email') {
                    if (validation.type === type && validation.value === value) {
                        isAble = validation.isValidated
                    }
                }
            })

            if (isAble) return !isAble

            await api.get('/' + this.clientType + '/validacoes', {
                headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
                if (type == 'telefone' || type === 'cellphone') {
                    if (response.data.telefones.length === 0) return true
                    response.data.telefones.forEach(telefone => {
                        if (telefone.telefone === this.removeMask(value)) {
                            isAble = telefone.validated
                        }
                    })
                }
                if (type == 'email') {
                    if (response.data.emails.length === 0) return true
                    response.data.emails.forEach(email => {
                        if (email.email == value) {
                            isAble = email.validated
                        }
                    })
                }
            }).catch((response) => {
                this.$emit('registerLastRequest', response)
            })

            return !isAble
        },

        getCode(value, type, field) {
            api.post('/' + this.clientType + '/validacoes', {
                value: type === 'email' ? value : this.removeMask(value),
                type,
            },
            {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }).catch((response) => {
              this.$emit('registerLastRequest', response)
            })

            if (type === 'cellphone') {
              this.validatingPhones[field] = true
            }

            if (type === 'email') {
              this.validatingEmails[field] = true
            }
        },

        send(index, value, type) {
          if (index && value && type) {
            api.put('/' + this.clientType + '/validacoes', {
                value: type === 'email' ? index : this.removeMask(index),
                type: type,
                code: value
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }).then(() => {
                this.initializeValidate()
            }).catch((response) => {
              this.$emit('registerLastRequest', response)
              this.$swal({
                  title: "Erro!",
                  text: 'O código informado é inválido.',
                  confirmButtonText: 'Ok'
              })
            })
          }
        },

        onChange(index, value) {
            this.send(index, value, String(index).includes('@') ? 'email' : 'cellphone')
        },

        errorCounter(data) {
            let counting = 0
            if (data && data.EmailValidated) counting++
            if (data && data.CelularValidated) counting++
            return counting
        },

        applyMask(index, value) {
            return utils.applyMask(index, value)
        },

        removeMask(value){
          return value
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('-', '')
            .replaceAll(' ', '')
        },
        async copy(value) {
            await new Promise(res => setTimeout(res, 100));
            this.$copyText(value);

            this.$swal.fire({
                icon: 'success',
                title: 'Texto copiado com sucesso!',
                position: 'center',
                showConfirmButton: false,
                timer: 1200
            });
        }

    }
}
</script>